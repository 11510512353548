<template>
  <div>
    <v-row v-if="validations.length">
      <v-col
        v-for="(validation, index) in getValidations"
        :key="index"
        cols="12"
      >
        <template
          v-if="
            validation.FromCustomerType && validation.ToCustomerType != null
          "
        >
          <clientTypeChangeCard
            @deleteOutArray="deleteOutArray($event)"
            :validation="validation"
          />
        </template>
        <template
          v-else-if="validation.FromAddress && validation.ToAddress != null"
        >
          <clientAddressChangeCard
            @deleteOutArray="deleteOutArray($event)"
            :validation="validation"
          />
        </template>
        <template
          v-else-if="
            validation.FromAddress == null && validation.ToAddress != null
          "
        >
          <template v-if="getUpdateAddressCheck(validation)">
            <clientAddNewAddress
              @deleteOutArray="deleteOutArray($event)"
              :validation="validation"
            />
          </template>
          <template v-else>
            <clientAddressUpdate
              @deleteOutArray="deleteOutArray($event)"
              :validation="validation"
            />
          </template>
        </template>
      </v-col>
    </v-row>
    <v-row v-else-if="!loadingValidations">
      <p class="text-h4">
        {{ $t("There are currently no more validation conflicts.") }}
      </p>
    </v-row>
  </div>
</template>

<script>
import request from "../../request";
export default {
  data() {
    return {
      validations: [],
      loadingValidations:true,
    };
  },
  computed: {
    getValidations: {
      get() {
        return this.validations;
      },
      set(newItem) {
        this.validations = newItem;
      },
    },
  },
  created() {
    this.getValidationErrors();
  },
  methods: {
    getValidationErrors() {
      request.get("/api/optiek-jo/validation-errors", null, (res) => {
        this.validations = res;
        this.loadingValidations = false;
      });
    },
    getUpdateAddressCheck(validation) {
      let check = true;
      for (const key in validation.ToAddress) {
        if (Object.hasOwnProperty.call(validation.ToAddress, key)) {
          if (!validation.ToAddress[key] && key != "Id") {
            check = false;
          }
        }
      }
      return check;
    },
    deleteOutArray(id) {
      this.validations.forEach((element, index) => {
        if (element.Id == id) {
          this.$delete(this.validations, index);
        }
      });
      if (this.validations.length == 0) {
        this.getValidationErrors();
      }
    },
  },
};
</script>

<style>
</style>